import axios from "axios";
import { backendUrl } from "../../url";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
};
export const getAllSpeeches = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(`${backendUrl}/api/speeches`, config);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
export const getAllSpeechVideos = async (speechId) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/api/speeches/${speechId}/videos`,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
export const getOneSpeech = async (speechId) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/api/speeches/${speechId}`,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
export const addSpeechAction = async (speechDetails) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.post(
      `${backendUrl}/api/feature/addspeech`,
      speechDetails,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error || error.response.data.message;
  }
};
export const improveSpeechAction = async (speechDetails) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.post(
      `${backendUrl}/api/feature/improvespeech`,
      speechDetails,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const deleteSpeechService = async (id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.delete(`${backendUrl}/api/speeches/${id}`, config);
    return response.data;
  } catch (error) {
    throw error.response.data.error || error.response.data.message;
  }
};
