import axios from "axios";
import { backendUrl } from "../../url";

export const getExpertStatistics = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/api/dashboard/expert/statistics`,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};


