/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs, Typography } from "@mui/material";
import RouterLink from "../../../routes/components/router-link";
import "./feedback.scss";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getVideo } from "../../../features/video/videoSlice";
import { useEffect } from "react";
import Loader from "../../../components/Loader";
import PlayableVideo from "components/video/PlayableVideo";
import FeedbackReview from "components/reviews/FeedbackReview";
import UserFeedbackReview from "components/reviews/UserFeedbackReview";
const ReviewFeedbackView = () => {
  const { videoId } = useParams();
  const dispatch = useDispatch();

  const { loading, videoDetails } = useSelector((state) => state.video);

  const checkVideoUrl = (param) => {
    if (param?.includes("https")) {
      return param;
    } else {
      let url =
        "https://xgolstorage.blob.core.windows.net/assessmentvideos/" + param;
      return url;
    }
  };

  useEffect(() => {
    // dispatch(clearState());
    dispatch(getVideo(videoId));
  }, []);

  return (
    <div className="feedback-container">
      <Breadcrumbs
        separator=">"
        aria-label="breadcrumb"
        className="breadcrumbs"
      >
        <RouterLink className="breadcrumbs" href={"/expert/reviews"} key="1">
          Reviews
        </RouterLink>
        <RouterLink
          className="breadcrumbs"
          href={`/expert/reviews/add/${videoId}`}
          key="2"
        >
     {videoDetails?.speech?.title || "Snack Reviews"}
        </RouterLink>
        <Typography key="3" color="text.primary" className="active_breadcrumb">
          See review
        </Typography>
      </Breadcrumbs>

      <>
        {!loading ? (
          <>
            <div className="content-header-container">
              <div className="title-wrapper">
                <div className="title-bg">See review</div>
              </div>
              <ul className="details-wrapper">
                <li>
                  {dayjs(
                    videoDetails?.coachEvaluationVideos[0]?.dateOfUpload
                  ).format("MMM DD,YYYY")}
                </li>
              </ul>
            </div>
            <div className="assessment-title">Your feedback review</div>
            <div className="w-full flex flex-row items-start justify-start">
              <PlayableVideo
                className="w-full max-w-[500px] mr-4"
                src={checkVideoUrl(
                  videoDetails?.coachEvaluationVideos[0]?.videoName
                )}
                
                playsInline
              />
              {videoDetails?.coachEvaluationVideos[0]?.ratingByUser &&
                videoDetails?.coachEvaluationVideos[0]?.feedbackByUser && (
                  <UserFeedbackReview
                    user={videoDetails?.user}
                    videoDetails={videoDetails}
                    feedbackReviewProps={{
                      className: "w-full",
                      feedbackTextClassName: "h-auto",
                    }}
                  />
                )}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </>
    </div>
  );
};

export default ReviewFeedbackView;
