import { Helmet } from "react-helmet-async";
import ReviewFeedbackView from "../../views/expert/review-feedback/ReviewFeedbackView";

const ExpertFeedbackPage = () => {
  return (
    <>
      <Helmet>
        <title>Feedback Review</title>
      </Helmet>

      <ReviewFeedbackView />
    </>
  );
};

export default ExpertFeedbackPage;
