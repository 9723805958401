import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteVideoService,
  getAllReviewsVideos,
  getAllVideos,
  getAllSnackCoachingVideos,
  getOneVideo,
  recordExpertVideoAction,
  recordVideoAction,
  recordSnackVideoAction,
  requestExpertAction,
} from "./videoService";
import _ from "lodash";

const initialState = {
  status: false,
  videos: null,
  videoDetails: null,
  isExpertUploading: false,
  message: null,
  loading: false,
  error: null,
  progress: 0,
};

export const clearState = createAsyncThunk("video/clearState", async () => {
  return true;
});
export const getReviewsVideos = createAsyncThunk(
  "video/getReviewsVideos",
  async (params, { rejectWithValue }) => {
    console.log("params", params);
    try {
      const videos = params
        ? await getAllReviewsVideos(params)
        : await getAllReviewsVideos(); // Check if params are provided
      return videos;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getVideos = createAsyncThunk(
  "video/getVideos",
  async (_, { rejectWithValue }) => {
    try {
      const videos = await getAllVideos();
      return videos;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getSnackCoachingVideos = createAsyncThunk(
  "video/getSnackCoachingVideos",
  async (_, { rejectWithValue }) => {
    try {
      const videos = await getAllSnackCoachingVideos();
      return videos;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getVideo = createAsyncThunk(
  "video/getVideoDetails",
  async (videoId, { rejectWithValue }) => {
    try {
      const video = await getOneVideo(videoId);
      return video;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const recordVideo = createAsyncThunk(
  "video/recordVideo",
  async ({ payload, abortController }, { dispatch, rejectWithValue }) => {
    try {
      await recordVideoAction({ payload, abortController }, (progress) => {
        dispatch(updateUploadProgress(progress));
      });
      dispatch(clearUploadProgress()); // Clear progress after upload completes
      return { status: "success", message: "Video uploaded successfully!" };
    } catch (error) {
      dispatch(clearUploadProgress());
      return rejectWithValue({ status: "failed", message: error.message });
    }
  }
);
export const recordSnackVideo = createAsyncThunk(
  "video/recordSnackVideo",
  async ({ payload, abortController }, { dispatch, rejectWithValue }) => {
    try {
      await recordSnackVideoAction({ payload, abortController }, (progress) => {
        dispatch(updateUploadProgress(progress));
      });
      dispatch(clearUploadProgress()); // Clear progress after upload completes
      return { status: "success", message: "Video uploaded successfully!" };
    } catch (error) {
      dispatch(clearUploadProgress());
      return rejectWithValue({ status: "failed", message: error.message });
    }
  }
);
export const requestExpert = createAsyncThunk(
  "video/requestExpert",
  async ({video,expert}, { rejectWithValue }) => {
    try {
      const response = await requestExpertAction({video,expert});
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const recordExpertVideo = createAsyncThunk(
  "video/recordExpertVideo",
  async (data, { rejectWithValue }) => {
    try {
      const videos = await recordExpertVideoAction(data);
      return videos;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "video/deleteVideo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteVideoService(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    updateUploadProgress: (state, action) => {
      state.progress = action.payload;
    },
    clearUploadProgress: (state) => {
      state.progress = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearState.fulfilled, (state) => {
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
        state.message = null;
        state.status = null;
      })
      .addCase(getVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(getVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = _.orderBy(
          action.payload.videos,
          (el) => el?.uploadedAt,
          ["desc"]
        );
        state.status = action.payload.status;
      })
      .addCase(getVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = false;
      })
      .addCase(getSnackCoachingVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(getSnackCoachingVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = _.orderBy(
          action.payload.videos,
          (el) => el?.uploadedAt,
          ["desc"]
        );
        state.status = action.payload.status;
      })
      .addCase(getSnackCoachingVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = false;
      })
      .addCase(getReviewsVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(getReviewsVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = _.orderBy(
          action.payload.videos,
          (el) => el?.uploadedAt,
          ["desc"]
        );
        state.status = action.payload.status;
      })
      .addCase(getReviewsVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = false;
      })
      .addCase(getVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(getVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.videoDetails = action.payload.video;
        state.status = action.payload.status;
      })
      .addCase(getVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = false;
      })
      .addCase(recordVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(recordVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.status = action.payload.status;
      })
      .addCase(recordVideo.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload.message;
        state.status = action.payload.status;
      })
      .addCase(recordSnackVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.videos = null;
        state.videoDetails = null;
      })
      .addCase(recordSnackVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.status = action.payload.status;
      })
      .addCase(recordSnackVideo.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload.message;
        state.status = action.payload.status;
      })
      .addCase(requestExpert.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(requestExpert.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(requestExpert.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload.message;
      })
      .addCase(recordExpertVideo.pending, (state) => {
        state.isExpertUploading = true;
        state.error = null;
      })
      .addCase(recordExpertVideo.fulfilled, (state, action) => {
        state.isExpertUploading = false;
        state.message = action.payload.message;
        state.status = action.payload.status;
      })
      .addCase(recordExpertVideo.rejected, (state, action) => {
        state.isExpertUploading = false;
        state.message = null;
        state.error = action.payload.message;
        state.status = action.payload.status;
      });
  },
});
export const { updateUploadProgress, clearUploadProgress } = videoSlice.actions;
export default videoSlice.reducer;
