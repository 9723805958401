// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getExpertStatistics } from "./expertService";

const initialState = {
  status: false,
  response: null,

  loading: false,

  error: null,
};

export const getExpertPortalData = createAsyncThunk(
  "expert/getExpertPortaldData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getExpertStatistics();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
  
      .addCase(getExpertPortalData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(getExpertPortalData.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload.data;
        state.status = action.payload.status;
      })
      .addCase(getExpertPortalData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = false;
      })
 
   
  },
});

export default expertSlice.reducer;
