export const getIcon = (name) => (
  <img src={`/assets/icons/navbar/${name}.svg`} />
);
export const getActiveIcon = (name) => (
  <img src={`/assets/icons/navbar/${name}-active.svg`} />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("dashboard"),
    activeIcon: getActiveIcon("dashboard"),
    roles: ["CLIENT"],
    buttonClassName: "_sidebarDashboardButton",
  },
  {
   title: "Snack Coaching",
    path: "/snack_coaching",
    icon: getIcon("learning"),
    activeIcon: getActiveIcon("learning"),
    roles: ["CLIENT"],
  },
  {
    title: "Snack Reviews",
    path: "/snack-reviews",
    icon: getIcon("expert-snack-review"),
    activeIcon: getActiveIcon("expert-snack-review"),
    roles: ["EXPERT"],
    buttonClassName: "_sidebarExpertReviewButton",
  },
  {
    title: "My Speeches",
    path: "/my_speeches",
    icon: getIcon("speech"),
    activeIcon: getActiveIcon("speech"),
    roles: ["CLIENT"],
    buttonClassName: "_sidebarSpeechButton",
  },
  {
    title: "My Videos",
    path: "/my_videos",
    icon: getIcon("videos"),
    activeIcon: getActiveIcon("videos"),
    roles: ["CLIENT"],
    buttonClassName: "_sidebarVideoButton",
  },
  {
    title: "Expert Reviews",
    path: "/expert_review",
    icon: getIcon("expert-review"),
    activeIcon: getActiveIcon("expert-review"),
    roles: ["CLIENT"],
    buttonClassName: "_sidebarExpertReviewButton",
  },
 
  
  {
    title: "Administration",
    path: "/admin/licenses",
    icon: getIcon("admin"),
    activeIcon: getActiveIcon("admin"),
    roles: ["ADMIN"],
  },
  {
    title: "Expert Portal",
    path: "/expertportal",
    icon: getIcon("admin"),
    activeIcon: getActiveIcon("admin"),
    roles: ["EXPERT"],
  },
  {
    title: "Reviews",
    path: "/expert/reviews",
    icon: getIcon("expert"),
    activeIcon: getActiveIcon("expert"),
    roles: ["EXPERT"],
    buttonClassName: "_sidebarUserReviewButton",
  },
];

export default navConfig;
